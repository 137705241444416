.loading .item {
    width: 100px;
    height: 12px;
    cursor: wait;
    background: linear-gradient(
        120deg,
        var(--hover-color) 30%,
        var(--strong-hover-color) 38%,
        var(--strong-hover-color) 40%,
        var(--hover-color) 48%
    );
    background-size: 300%;
    background-position: 100% 0;
    animation: anim 2s infinite;
}

.loading .item.height {
    height: 20px;
}

.loading .item.height.medium {
    height: 15px;
}

.loading .item.height.button {
    height: var(--button-height);
}

.loading .item.small {
    width: 60px;
}

.loading .item.large {
    width: 170px;
}

.loading .item.super-large {
    width: 220px;
}
.loading .item.width-100 {
    width: 100%;
}

.loading .item.icon {
    width: var(--icon-size);
    height: var(--icon-size);
    border-radius: 50%;
}

.loading .item.icon.small {
    --icon-size: 20px;
}
.loading .item.icon.large {
    --icon-size: 30px;
}
.loading .item.icon.super-large {
    --icon-size: 40px;
}
.loading .item.margin-bottom {
    margin-bottom: 5px;
}

.loading-container {
    background-color: var(--secondary-layer-color);
    padding: var(--container-padding);
    border-radius: var(--border-radius);
    cursor: wait;
}

.loading-container.form {
    height: 45px; /* form height */
}
.loading-container.button {
    width: 100%;
    border-radius: var(--border-radius-btn);
    height: var(--button-height);
}

@keyframes anim {
    to {
        background-position: -100% 0;
    }
}
