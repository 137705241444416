.light-theme {
    --background-container-color: var(--primary-layer-color);
}
.dark-theme {
    --background-container-color: var(--app-background);
}
.light-theme,
.dark-theme,
:root {
    --mdc-typography-font-family: var(--font-family);
    --mdc-typography-body1-font-family: var(--font-family);
    --mdc-typography-body2-font-family: var(--font-family);
    --mdc-typography-body1-font-size: var(--font-normal);
    --mdc-typography-body2-font-size: var(--font-normal);
    --mdc-typography-body1-line-height: var(--font-normal);
    --mdc-typography-body2-line-height: var(--font-normal);
    --mdc-typography-subtitle1-font-size: var(--font-normal);
    --mdc-typography-subtitle1-line-height: var(--font-normal);
    --mdc-typography-subtitle2-font-size: var(--font-normal);
    --mdc-typography-subtitle2-line-height: var(--font-normal);
    --mdc-shape-medium: var(--border-radius);
    --mdc-shape-small: var(--border-radius-btn);
    --mdc-typography-body1-letter-spacing: 0;
    --mdc-typography-body2-letter-spacing: 0;
    --mdc-typography-subtitle1-letter-spacing: 0;
    --mdc-typography-subtitle2-letter-spacing: 0;
    --mdc-theme-text-primary-on-background: var(--text-color);
    --mdc-theme-text-secondary-on-background: var(--text-color-soft);
    --mdc-theme-text-disabled-on-background: var(--text-color-disabled);
    --mdc-theme-text-icon-on-background: var(--button-color);
    --mdc-icon-button-icon-color: var(--button-color);
    --mat-select-panel-background-color: var(--background-container-color);
    --mat-select-enabled-trigger-text-color: var(--text-color);
    --mat-select-disabled-trigger-text-color: var(--text-color-disabled);
    --mat-select-placeholder-text-color: var(--text-color);
    --mat-select-enabled-arrow-color: var(--text-color-soft);
    --mat-select-disabled-arrow-color: var(--disabled-color);
    --mat-select-trigger-text-font: var(--font-family);
    --mat-select-trigger-text-line-height: 24px;
    --mat-select-trigger-text-size: var(--font-normal);
    --mat-select-trigger-text-tracking: 0;
    --mdc-theme-on-surface: var(--text-color);
    --mat-paginator-container-text-color: var(--text-color);
    --mat-paginator-container-background-color: var(--background-container-color);
    --mat-paginator-enabled-icon-color: var(--text-color-soft);
    --mat-paginator-disabled-icon-color: var(--disabled-color);
    --mat-option-label-text-color: var(--text-color);
    --mat-option-label-text-line-height: var(--font-normal);
    --mat-option-label-text-size: var(--font-normal);
    --mat-option-label-text-tracking: 0;
    --mat-menu-item-label-text-size: var(--font-normal);
    --mat-menu-item-label-text-line-height: var(--font-normal);
    --mat-menu-item-label-text-tracking: 0;
    --mat-menu-item-label-text-color: var(--text-color);
    --mat-menu-item-icon-color: var(--text-color);
    --mat-menu-item-hover-state-layer-color: var(--hover-color);
    --mat-menu-item-focus-state-layer-color: var(--hover-color);
    --mat-menu-container-color: var(--background-container-color);
    --mat-menu-container-shape: var(--border-radius);
    --mat-table-header-headline-line-height: var(--font-normal);
    --mat-table-header-headline-size: var(--font-normal);
    --mat-table-header-headline-tracking: 0;
    --mat-table-row-item-label-text-line-height: var(--font-normal);
    --mat-table-row-item-label-text-size: var(--font-normal);
    --mat-table-row-item-label-text-tracking: 0;
    --mat-table-footer-supporting-text-line-height: var(--font-normal);
    --mat-table-footer-supporting-text-size: var(--font-normal);
    --mat-table-footer-supporting-text-tracking: 0;
    --mdc-filled-text-field-label-text-size: var(--font-normal);
    --mdc-filled-text-field-label-text-tracking: 0;
    --mdc-outlined-text-field-label-text-size: var(--font-normal);
    --mdc-outlined-text-field-label-text-tracking: 0;
    --mat-form-field-container-text-line-height: var(--font-normal);
    --mat-form-field-container-text-size: var(--font-normal);
    --mat-form-field-container-text-tracking: 0;
    --mat-form-field-outlined-label-text-populated-size: var(--font-normal);
    --mat-form-field-subscript-text-line-height: var(--font-sm);
    --mat-form-field-subscript-text-size: var(--font-sm);
    --mat-form-field-subscript-text-tracking: 0;
    --mdc-filled-text-field-container-color: var(--text-color);
    --mdc-filled-text-field-disabled-container-color: var(--text-color);
    --mdc-filled-text-field-label-text-color: var(--text-color-soft);
    --mdc-filled-text-field-disabled-label-text-color: var(--text-color-disabled);
    --mdc-filled-text-field-input-text-color: var(--text-color);
    --mdc-filled-text-field-disabled-input-text-color: var(--text-color-disabled);
    --mdc-filled-text-field-input-text-placeholder-color: var(--text-color-soft);
    --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
    --mdc-filled-text-field-disabled-active-indicator-color: var(--disabled-color);
    --mdc-filled-text-field-hover-active-indicator-color: var(--text-color);
    --mdc-outlined-text-field-label-text-color: var(--text-color-soft);
    --mdc-outlined-text-field-disabled-label-text-color: var(--text-color-disabled);
    --mdc-outlined-text-field-input-text-color: var(--text-color);
    --mdc-outlined-text-field-disabled-input-text-color: var(--text-color-disabled);
    --mdc-outlined-text-field-input-text-placeholder-color: var(--text-color-soft);
    --mdc-outlined-text-field-outline-color: var(--divider-color);
    --mdc-outlined-text-field-disabled-outline-color: var(--disabled-color);
    --mdc-outlined-text-field-hover-outline-color: var(--text-color);
    --mat-form-field-disabled-input-text-placeholder-color: var(--text-color-disabled);
    --mat-divider-color: var(--divider-color);
    --mat-table-footer-container-height: var(--item-height);
    --mat-table-row-item-container-height: var(--item-height);
    --mat-paginator-container-size: var(--item-height);
    --mdc-text-button-disabled-label-text-color: var(--text-color-disabled) !important;
    --mdc-text-button-label-text-color: var(--text-color-disabled) !important;
    --mdc-checkbox-unselected-icon-color: var(--divider-stronger-color);
    --mdc-checkbox-unselected-pressed-icon-color: var(--divider-stronger-color);
    --mat-badge-background-color: var(--color);
    --mat-badge-text-color: var(--color-text);
    --mat-checkbox-label-text-color: var(--text-color);
    --mat-form-field-container-height: 45px;
    --mat-form-field-container-vertical-padding: 10px;
}
.mat-mdc-dialog-container {
    --mdc-dialog-subhead-font: var(--font-family);
    --mdc-dialog-subhead-line-height: var(--font-lg);
    --mdc-dialog-subhead-size: var(--font-lg);
    --mdc-dialog-supporting-text-font: var(--font-family);
    --mdc-dialog-supporting-text-line-height: var(--font-normal);
    --mdc-dialog-supporting-text-size: var(--font-normal);
    --mdc-dialog-container-color: var(--background-container-color);
    --mdc-dialog-subhead-color: var(--text-color);
    --mdc-dialog-supporting-text-color: var(--text-color);
    --mdc-dialog-container-shape: var(--border-radius) !important;
    --mdc-dialog-supporting-text-tracking: 0;
}
.mat-mdc-unelevated-button.mat-unthemed {
    --mdc-filled-button-container-color: var(--hover-color);
    --mdc-filled-button-label-text-color: var(--text-color);
}
.mat-mdc-unelevated-button.mat-warn {
    --mdc-filled-button-label-text-color: white;
}
.mat-mdc-checkbox.mat-accent,
.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-unselected-icon-color: var(--text-color-soft);
    --mdc-checkbox-unselected-pressed-icon-color: var(--text-color-soft);
    --mdc-checkbox-disabled-selected-icon-color: var(--text-color-disabled);
    --mdc-checkbox-disabled-unselected-icon-color: var(--text-color-disabled);
    --mdc-checkbox-unselected-focus-icon-color: var(--divider-stronger-color);
    --mdc-checkbox-unselected-hover-icon-color: var(--divider-stronger-color);
}
.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-focus-icon-color: var(--accent-color);
    --mdc-checkbox-selected-hover-icon-color: var(--accent-color);
    --mdc-checkbox-selected-icon-color: var(--accent-color);
    --mdc-checkbox-selected-pressed-icon-color: var(--accent-color);
}
.mdc-list {
    --mdc-typography-subtitle1-font-family: var(--font-family);
    --mdc-typography-subtitle1-font-size: var(--font-normal);
    --mdc-typography-subtitle1-line-height: var(--font-normal);
    --mdc-typography-subtitle1-letter-spacing: 0;
    line-height: var(--mdc-typography-subtitle1-line-height);
}
.mat-mdc-checkbox .mdc-form-field {
    --mdc-theme-text-primary-on-background: var(--text-color);
}
.mat-mdc-tooltip {
    --mdc-plain-tooltip-supporting-text-font: var(--font-family);
    --mdc-plain-tooltip-supporting-text-size: var(--font-sm);
    --mdc-plain-tooltip-supporting-text-tracking: 0;
    --mdc-plain-tooltip-container-color: var(--tooltip-bg-color);
    --mdc-plain-tooltip-supporting-text-color: white;
}
.mat-mdc-icon-button {
    --mat-mdc-button-persistent-ripple-color: var(--text-color);
    --mat-mdc-button-ripple-color: var(--hover-color);
}
.mat-mdc-unelevated-button[disabled][disabled] {
    --mdc-filled-button-disabled-container-color: var(--disabled-color);
    --mdc-filled-button-disabled-label-text-color: var(--disabled-color);
}
.mdc-button {
    --mdc-typography-button-font-size: var(--font-normal);
    --mdc-typography-button-line-height: var(--font-normal);
    --mdc-typography-button-letter-spacing: 0;
}
.mat-mdc-button {
    --mdc-text-button-container-shape: var(--border-radius-btn);
}
.mat-mdc-button,
.mat-mdc-outlined-button {
    --mat-mdc-button-persistent-ripple-color: var(--color);
    --mat-mdc-button-ripple-color: var(--hover-color);
}
.mdc-button.mat-unthemed,
.mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: var(--text-color);
    --mdc-outlined-button-label-text-color: var(--text-color);
}
.mat-mdc-outlined-button {
    --mdc-outlined-button-outline-color: var(--divider-color);
}
.mat-mdc-button[disabled][disabled] {
    --mdc-text-button-disabled-label-text-color: var(--text-color-disabled) !important;
    --mdc-text-button-label-text-color: var(--text-color-disabled) !important;
}
.mat-mdc-progress-bar.mat-success {
    --mdc-linear-progress-active-indicator-color: var(--success-color);
    --mdc-linear-progress-track-color: rgba(30, 210, 115, 0.25);
}
.mat-mdc-slide-toggle {
    --mat-switch-label-text-color: var(--text-color) !important;
}
.mat-mdc-slide-toggle.mat-green {
    --mdc-switch-selected-focus-state-layer-color: var(--green-mid-color);
    --mdc-switch-selected-handle-color: var(--green-color);
    --mdc-switch-selected-hover-state-layer-color: var(--green-mid-color);
    --mdc-switch-selected-pressed-state-layer-color: var(--green-mid-color);
    --mdc-switch-selected-focus-handle-color: var(--green-color);
    --mdc-switch-selected-hover-handle-color: var(--green-color);
    --mdc-switch-selected-pressed-handle-color: var(--green-color);
    --mdc-switch-selected-focus-track-color: var(--green-soft-color);
    --mdc-switch-selected-hover-track-color: var(--green-soft-color);
    --mdc-switch-selected-pressed-track-color: var(--green-soft-color);
    --mdc-switch-selected-track-color: var(--green-soft-color);
}
.mat-mdc-checkbox.mat-green {
    --mdc-checkbox-selected-focus-icon-color: var(--green-color);
    --mdc-checkbox-selected-hover-icon-color: var(--green-color);
    --mdc-checkbox-selected-icon-color: var(--green-color);
    --mdc-checkbox-selected-pressed-icon-color: var(--green-color);
    --mdc-checkbox-selected-focus-state-layer-color: var(--green-color);
    --mdc-checkbox-selected-hover-state-layer-color: var(--green-color);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--green-color);
}
.mat-mdc-card {
    --mdc-elevated-card-container-color: var(--background-container-color);
    --mdc-elevated-card-container-shape: var(--border-radius);
}
.mat-mdc-select {
    --mat-select-trigger-text-font: var(--font-family);
    --mat-select-trigger-text-size: var(--font-normal);
}
.mat-mdc-icon-button[disabled][disabled] {
    --mdc-icon-button-icon-color: var(--text-color-disabled);
    --mdc-icon-button-disabled-icon-color: var(--text-color-disabled);
}
.mat-mdc-radio-button.mat-primary {
    --mdc-radio-disabled-selected-icon-color: var(--text-color);
    --mdc-radio-disabled-unselected-icon-color: var(--text-color);
    --mdc-radio-unselected-hover-icon-color: var(--text-color);
    --mdc-radio-unselected-icon-color: var(--text-color-soft);
    --mdc-radio-unselected-pressed-icon-color: var(--text-color-soft);
    --mat-radio-ripple-color: var(--text-color);
    --mat-radio-disabled-label-color: var(--text-color-disabled);
}
.mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: var(--border-radius-input) !important;
}
.mat-mdc-menu-panel {
    --mat-menu-container-shape: var(--border-radius) !important;
}
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
    --mdc-typography-caption-font-size: var(--font-sm);
    --mdc-typography-caption-line-height: var(--font-sm);
    --mdc-typography-caption-letter-spacing: 0;
}

.mat-datepicker-content,
.mdc-menu-surface {
    background-color: var(--background-container-color);
}
.mat-mdc-select-panel,
.cdk-overlay-pane:not(.mat-mdc-select-panel-above),
.mat-datepicker-content {
    border-radius: var(--border-radius) !important;
}
.mat-pseudo-checkbox,
.mdc-checkbox__background {
    border-radius: var(--border-radius-small) !important;
}

.mat-mdc-menu-content {
    padding: var(--container-padding-super-small) !important;
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell,
.mat-datepicker-content,
.mat-drawer-container,
.dark-theme .mat-calendar-body-cell-content,
.mat-date-range-input-separator,
.mat-mdc-list-item-title,
.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end,
.mat-mdc-dialog-container .mdc-dialog__title,
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.mdc-menu-surface,
.mat-expansion-panel,
.mat-step-header .mat-step-label.mat-step-label-active,
.mat-expansion-panel-header-title,
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: var(--text-color) !important;
}

.mat-mdc-icon-button svg,
.mat-mdc-icon-button img,
.mat-mdc-paginator,
.mdc-data-table__header-cell,
.mat-mdc-select-arrow,
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.mat-mdc-card-subtitle,
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after,
.mat-calendar-table-header,
.mat-calendar-body-label,
.mat-mdc-checkbox-disabled label,
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
    color: var(--text-color-soft);
}

.mat-mdc-slide-toggle .mdc-switch--disabled + label,
.mat-calendar-body-disabled
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
    ) {
    color: var(--text-color-disabled) !important;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.mat-divider {
    border-color: var(--divider-color);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading:hover,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch:hover,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing:hover {
    border-color: var(--strong-hover-color);
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
    background: var(--hover-color) !important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
    .mdc-notched-outline
    .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
    .mdc-notched-outline
    .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: var(--divider-stronger-color) !important;
}
.mat-mdc-form-field
    .mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    font-size: var(--font-normal) !important;
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
    color: var(--button-color) !important;
}
.mat-calendar-table-header-divider::after {
    background-color: var(--divider-color) !important;
}
.mat-expansion-panel-header,
.mat-mdc-form-field
    .mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    font-size: var(--font-normal) !important;
}
.mat-calendar {
    font-family: var(--font-family);
}
.mat-mdc-paginator-icon,
.mat-calendar-arrow {
    fill: var(--text-color-soft);
}
.mat-mdc-paginator-icon {
    fill: var(--text-color-soft);
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
    fill: var(--text-color-disabled);
}
.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
    border-top-color: var(--divider-color);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: var(--disabled-color);
}
.mdc-text-field--disabled .mdc-floating-label,
.mdc-text-field--disabled .mdc-text-field__input {
    color: var(--text-color-disabled);
}
.mdc-notched-outline__notch,
.mdc-notched-outline__leading,
.mdc-notched-outline__trailing {
    background-color: var(--primary-background);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
    z-index: 9;
}

.mat-icon {
    width: var(--icon-size) !important;
    height: var(--icon-size) !important;
    font-size: var(--icon-size) !important;
    line-height: var(--icon-size) !important;
}
.mat-mdc-icon-button.mat-mdc-button-base {
    width: calc(var(--icon-size) * 1.8);
    height: calc(var(--icon-size) * 1.8);
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
}

.mdc-dialog__title {
    height: 65px;
    padding: 0 24px !important;
    margin: 0 !important;
}
.mdc-dialog__title .title {
    height: 100%;
    font-weight: bold;
    font-size: var(--font-super-lg);
    display: flex;
    align-items: center;
    text-transform: uppercase;
}
.mdc-dialog__title::before {
    display: none !important;
}
.mat-mdc-dialog-content.padding-bottom {
    padding-bottom: 5px !important;
}
.mat-mdc-dialog-actions {
    justify-content: end !important;
    padding: 0 24px 8px 24px !important;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
    overflow: hidden;
    box-shadow: none !important;
    border: 1px solid var(--divider-color);
}
.mdc-menu-surface.mat-mdc-select-panel {
    padding: 0 !important;
}
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
    padding: 0 5px !important;
}
.mdc-floating-label {
    will-change: unset !important;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    display: flex;
}
.mdc-notched-outline .mdc-floating-label {
    display: unset !important;
    position: absolute !important;
}
.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
    margin-left: 12px;
}
.mdc-checkbox__checkmark {
    width: 10px !important;
    left: 2px !important;
    top: 2px !important;
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
.mat-pseudo-checkbox-full {
    color: var(--button-color);
}
.mat-mdc-chip-remove .mat-icon {
    --icon-size: 18px;
}
.mdc-form-field > label {
    padding-left: 5px !important;
}
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background: darkgray !important;
}
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
    background: transparent !important;
}
.mdc-switch__icons {
    display: none;
}
.mat-mdc-list-item .mdc-list-item__primary-text {
    letter-spacing: 0 !important;
}
.mdc-tooltip__surface {
    line-height: unset !important;
    padding: 4px 10px !important;
    min-height: unset !important;
    /*text-transform: uppercase;*/
}

.mdc-list-item__primary-text {
    width: 100%;
}

.mat-mdc-progress-bar {
    border-radius: var(--border-radius);
}

.cdk-overlay-backdrop {
    backdrop-filter: blur(5px);
}
