.flex-row {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
}
.flex-row-wrap {
    display: flex;
    box-sizing: border-box;
    flex-flow: row wrap;
}
.flex-column {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}
.flex-column-wrap {
    display: flex;
    box-sizing: border-box;
    flex-flow: column wrap;
}

/* center */
.align-center-center {
    display: flex;
    box-sizing: border-box;
    place-content: center;
    align-items: center;
}
.align-center-start {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    place-content: flex-start center;
}
.align-center-end {
    display: flex;
    box-sizing: border-box;
    align-items: flex-end;
    place-content: flex-end center;
}
.align-center-stretch {
    display: flex;
    box-sizing: border-box;
    align-items: stretch;
    place-content: stretch center;
}

/* start */
.align-start-center {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    place-content: center flex-start;
}
.align-start-start {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    place-content: flex-start;
}
.align-start-end {
    display: flex;
    box-sizing: border-box;
    align-items: flex-end;
    place-content: flex-end flex-start;
}
.align-start-stretch {
    display: flex;
    box-sizing: border-box;
    align-items: stretch;
    place-content: stretch flex-start;
}

/* end */
.align-end-center {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    place-content: center flex-end;
}
.align-end-start {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    place-content: flex-start flex-end;
}
.align-end-end {
    display: flex;
    box-sizing: border-box;
    align-items: flex-end;
    place-content: flex-end;
}
.align-end-stretch {
    display: flex;
    box-sizing: border-box;
    align-items: stretch;
    place-content: stretch flex-end;
}

/* space-around */
.align-space-around-center {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    place-content: center space-around;
}
.align-space-around-start {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    place-content: flex-start space-around;
}
.align-space-around-end {
    display: flex;
    box-sizing: border-box;
    align-items: flex-end;
    place-content: flex-end space-around;
}
.align-space-around-stretch {
    display: flex;
    box-sizing: border-box;
    align-items: stretch;
    place-content: stretch space-around;
}

/* space-between */
.align-space-between-center {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    place-content: center space-between;
}
.align-space-between-start {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    place-content: flex-start space-between;
}
.align-space-between-end {
    display: flex;
    box-sizing: border-box;
    align-items: flex-end;
    place-content: flex-end space-between;
}
.align-space-between-stretch {
    display: flex;
    box-sizing: border-box;
    align-items: stretch;
    place-content: stretch space-between;
}

/* space-evenly */
.align-space-evenly-center {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    place-content: center space-evenly;
}
.align-space-evenly-start {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    place-content: flex-start space-evenly;
}
.align-space-evenly-end {
    align-items: flex-end;
    place-content: flex-end space-evenly;
}
.align-space-evenly-stretch {
    display: flex;
    box-sizing: border-box;
    align-items: stretch;
    place-content: stretch space-evenly;
}

.flex {
    flex: 1 1 0%;
}
.flex-5 {
    flex: 5%;
}
.flex-6 {
    flex: 6%;
}
.flex-7 {
    flex: 7%;
}
.flex-10 {
    flex: 10%;
}
.flex-15 {
    flex: 15%;
}
.flex-20 {
    flex: 20%;
}
.flex-22 {
    flex: 22%;
}
.flex-25 {
    flex: 25%;
}
.flex-27 {
    flex: 27%;
}
.flex-30 {
    flex: 30%;
}
.flex-33 {
    flex: 33%;
}
.flex-35 {
    flex: 35%;
}
.flex-40 {
    flex: 40%;
}
.flex-45 {
    flex: 45%;
}
.flex-47 {
    flex: 47%;
}
.flex-48 {
    flex: 48%;
}
.flex-50 {
    flex: 50%;
}
.flex-55 {
    flex: 55%;
}
.flex-60 {
    flex: 60%;
}
.flex-65 {
    flex: 65%;
}
.flex-70 {
    flex: 70%;
}
.flex-75 {
    flex: 75%;
}
.flex-80 {
    flex: 80%;
}
.flex-85 {
    flex: 85%;
}
.flex-87 {
    flex: 87%;
}
.flex-90 {
    flex: 90%;
}
.flex-93 {
    flex: 93%;
}
.flex-95 {
    flex: 95%;
}
.flex-100 {
    flex: 100%;
}

.margin-left-5 {
    margin-left: 5%;
}
.margin-left-10 {
    margin-left: 10%;
}
.margin-left-15 {
    margin-left: 15%;
}

.margin-left-15-px {
    margin-left: 15px;
}

.margin-right-5 {
    margin-right: 5%;
}
.margin-right-10 {
    margin-right: 10%;
}

.margin-bottom-5 {
    margin-bottom: 5%;
}
.margin-bottom-10 {
    margin-bottom: 10%;
}

.margin-top-5 {
    margin-top: 5%;
}
.margin-top-10 {
    margin-top: 10%;
}

.row-gap-page-margin {
    row-gap: var(--page-margin);
}
.row-gap-5 {
    row-gap: 5%;
}
.row-gap-10 {
    row-gap: 10%;
}
.row-gap-15 {
    row-gap: 15%;
}

.row-gap-15-px {
    row-gap: 15px;
}

.column-gap-5 {
    column-gap: 5%;
}
.column-gap-10 {
    column-gap: 10%;
}
.column-gap-15 {
    column-gap: 15%;
}
.column-gap-35 {
    column-gap: 35%;
}

.column-gap-page-margin {
    column-gap: var(--page-margin);
}
.column-gap-item-margin {
    column-gap: var(--item-margin);
}
.column-gap-20-px {
    column-gap: 20px;
}
.column-gap-35-px {
    column-gap: 35px;
}

.flex-grow-1 {
    flex-grow: 1;
}
