.slider-toggle {
    --option-height: var(--button-height);
    --border-radius: 25px;
    width: fit-content;
    padding: 4px;
    background: var(--secondary-layer-color);
    border-radius: var(--border-radius);
    position: relative;
}

.slider-toggle .option {
    transition: all 0.225s;
    cursor: pointer;
    line-height: var(--option-height);
    text-align: center;
    z-index: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.slider-toggle .option.active {
    color: white;
}
.dark-theme .slider-toggle .option.active {
    color: black;
}

.slider-toggle .selected {
    position: absolute;
    height: var(--option-height);
    z-index: 0;
    transition: margin-left 0.225s;
    border-radius: var(--border-radius);
    background-color: var(--color);
}
.slider-toggle.disable-animation .selected {
    transition: none !important;
}
