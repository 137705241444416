.field {
    background-color: var(--secondary-layer-color);
    min-height: var(--item-height);
    padding: 0 var(--field-padding);
    border-radius: var(--border-radius);
    min-width: 300px;

    &.small {
        font-style: italic;
        min-width: 240px;
    }

    &.medium {
        font-style: italic;
        min-width: 340px;
    }

    &.margin {
        margin-bottom: var(--field-margin);
    }

    .label {
        color: var(--text-color-soft);
        min-width: 140px;
        max-width: 140px;
        margin-right: var(--item-margin);
        text-transform: uppercase;
    }

    .text {
        overflow: hidden;
        word-break: break-word;
        padding-inline: 2px;

        &:not(.font-style-normal) {
            font-style: italic;
        }
    }
}
