// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'palette' as acmtool;

// imports for other components in your app.
@import 'styles';
@import '../css/root-variables.css';
@import '../css/flex-layout.css';
@import '../css/loading-skeleton.css';
@import '../css/slider-toggle.css';
@import '../css/field';
@import '@material-design-icons/font/outlined.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$acm-primary: mat.m2-define-palette(acmtool.$acm-palette);
$acm-accent: mat.m2-define-palette(acmtool.$acm-accent-palette);

// The warn palette is optional (defaults to red).
$acm-warn: mat.m2-define-palette(acmtool.$acm-red-palette);

// Typography
$acm-typography: mat.m2-define-typography-config(
    $font-family: var(--font-family),
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$acm-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $acm-primary,
            accent: $acm-accent,
            warn: $acm-warn,
        ),
        typography: $acm-typography,
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($acm-theme);
