body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family), serif;
    font-size: var(--font-normal);
    background: var(--app-background);
    color: var(--text-color);
    overflow: auto;
    -webkit-font-smoothing: antialiased;
}

body.background-image {
    background-image: url('../assets/images/flow-manager-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    overflow: hidden;
}

html.dark-theme,
body.dark-theme {
    color-scheme: dark;
}

@font-face {
    font-family: 'KONE_FONT';
    src:
        url(../assets/fonts/KONE/KONE_Information.ttf) format('truetype'),
        url(../assets/fonts/KONE/KONE_Information.woff) format('woff'),
        url(../assets/fonts/KONE/KONE_Information.woff2) format('woff2');
}

/* ================= INSTANT CLASS NAMES ================= */
.cursor-pointer {
    cursor: pointer;
}
.cursor-default {
    cursor: default;
}
.cursor-not-allowed {
    cursor: not-allowed !important;
}
.outline-none {
    outline: none;
}
.width-100 {
    width: 100%;
}
.height-100 {
    height: 100%;
}
.position-relative {
    position: relative;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-lowercase {
    text-transform: lowercase;
}
.text-capitalize {
    text-transform: capitalize;
}
.text-transform-none {
    text-transform: none;
}
.text-underline {
    text-decoration: underline;
}
.text-center {
    text-align: center;
}
.text-bold {
    font-weight: bold !important;
}
.text-italic {
    font-style: italic;
}
.text-normal {
    font-style: normal;
    font-weight: normal;
}
.word-break-all {
    word-break: break-all;
}
.pre-line {
    white-space: pre-line;
}
.break-spaces {
    white-space: break-spaces;
}
.no-wrap {
    white-space: nowrap;
}
.line-through {
    text-decoration: line-through;
}
.display-none {
    display: none !important;
}
.display-block {
    display: block;
}
.display-inherit {
    display: inherit;
}
.text-ellipses {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.overflow-hidden {
    overflow: hidden !important;
}
.pointer-events-none {
    pointer-events: none;
}
.text-color {
    color: var(--text-color);
}
.text-color-soft {
    color: var(--text-color-soft);
}
.font-size-sm {
    font-size: var(--font-sm);
}
.font-size-normal {
    font-size: var(--font-normal);
}
.font-size-md {
    font-size: var(--font-md);
}
.font-size-lg {
    font-size: var(--font-lg);
}
.font-size-super-lg {
    font-size: var(--font-super-lg);
}
.font-size-xl {
    font-size: var(--font-xl);
}
.font-size-super-xl {
    font-size: var(--font-super-xl);
}
.error-text-color {
    color: var(--error-color) !important;
}
.delete-text-color {
    color: var(--delete-color) !important;
}
.green-text-color {
    color: var(--green-color) !important;
}
.primary-color {
    color: var(--primary-color);
}
.delete-bg-color {
    background-color: var(--delete-color);
}
.delete-soft-bg-color {
    background-color: var(--delete-soft-color);
}
.app-background {
    background-color: var(--app-background) !important;
}
.primary-layer-color {
    background-color: var(--primary-layer-color) !important;
}
.secondary-layer-color {
    background-color: var(--secondary-layer-color) !important;
}
.tertiary-layer-color {
    background-color: var(--tertiary-layer-color) !important;
}
.fourth-layer-color {
    background-color: var(--fourth-layer-color) !important;
}
.fifth-layer-color {
    background-color: var(--fifth-layer-color) !important;
}
.footer-color {
    background-color: var(--footer-color) !important;
}
.side-menu-color {
    background-color: var(--side-menu-color) !important;
}
.border-radius {
    border-radius: var(--border-radius);
}
.border-radius-btn {
    border-radius: var(--border-radius-btn);
}
.border-radius-super {
    border-radius: var(--border-radius-super);
}
.container-padding {
    padding: var(--container-padding);
}
.container-padding-inline {
    padding-inline: var(--container-padding);
}
.container-padding-super-small {
    padding-inline: var(--container-padding-super-small);
}
.item-margin-left {
    margin-left: var(--item-margin);
}
.item-margin-left-half {
    margin-left: var(--item-margin-half);
}
.item-margin-right {
    margin-right: var(--item-margin);
}
.item-margin-bottom {
    margin-bottom: var(--item-margin);
}
.item-margin-bottom-half {
    margin-bottom: var(--item-margin-half);
}
.item-margin-top {
    margin-top: var(--item-margin);
}
.item-margin-top-half {
    margin-top: var(--item-margin-half);
}
.page-margin {
    margin: 0 var(--page-margin);
}
.page-margin-bottom {
    margin-bottom: var(--page-margin);
}
.page-margin-top {
    margin-top: var(--page-margin);
}
.page-margin-right {
    margin-right: var(--page-margin);
}
.page-margin-left {
    margin-left: var(--page-margin);
}
.page-margin-left-half {
    margin-left: var(--page-margin-half);
}
.page-margin-right-half {
    margin-right: var(--page-margin-half);
}
.page-margin-top-half {
    margin-top: var(--page-margin-half);
}
.page-margin-bottom-half {
    margin-bottom: var(--page-margin-half);
}
.spinner-text-color {
    --mdc-circular-progress-active-indicator-color: var(--text-color-soft) !important;
}

.mdc-button {
    --mdc-typography-button-text-transform: uppercase;
}

.btn-icon {
    .icon {
        margin-right: var(--icon-margin-btn);
        margin-left: calc(var(--icon-margin-btn) * -1);
    }

    .spinner {
        margin-right: calc(var(--icon-margin-btn) + 6px);
    }
}

.btn-action-dialog {
    text-transform: uppercase;
    width: 100%;
    margin-left: 0 !important;
}

.two-lines-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

input {
    color: var(--text-color);
    font-size: var(--font-normal);
    font-family: var(--font-family);
}

::placeholder {
    color: var(--text-color-soft);
}

mat-form-field textarea {
    min-height: 100px;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
    /* full width */
    top: 0 !important;
    right: 0 !important;
    width: 100% !important;
}

.toast-container * {
    box-sizing: unset !important;
}

/* fix: ng ui scroll bottom padding for few items */
div[data-padding-forward] {
    display: none;
}
